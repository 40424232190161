<template>
	<div>
		<div class="flex flex-col bg-white border rounded">
			<div class="w-full border-bottom p-[16px] flex items-center gap-2">
				<img src="@/assets/img/icons/search.svg" class="shrink-0" alt="">
				<input placeholder="Search" v-model="search" class="border-none outline-none text-sm flex-grow" />
			</div>
			<b-table show-empty :busy="loading" responsive striped :items="chargeTypes" :fields="fields" class='cursor-pointer'>
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<strong>Loading...</strong>
					</div>
				</template>
				<template #empty>
					<h4 class="font-medium text-center p-5">No charge type has been created, click “add charge type” to create a new charge types</h4>
				</template>
				<template #cell(sn)="data">
					{{ (currentPage - 1) * perPage + data.index + 1 }}
				</template>
				<template #cell(type)="data">
					<p class="text-sm text-[#737876] whitespace-nowrap"><span class="text-[#101211] uppercase">{{ data.item.short_name }}</span> {{data.item.name}}</p>
				</template>
				<template #cell(desc)="data">
					<p class="text-sm text-[#737876]">
						{{data.item.description}}
					</p>
				</template>
				<template #cell(updated_by)="data">
					<p class="text-sm text-[#2C8EED] font-medium">{{ data.item.updated_by || 'N/A' }}</p>
				</template>
				<template #cell(time)="data">
					<div class="flex flex-col gap-1 text-xs text-[#313533] font-medium">
						<p>{{moment(data.item.created_at).format('LT')}}</p>
						<p>{{ moment(data.item.created_at).format('Do MMMM, YYYY') }}</p>
					</div>
				</template>
				<template #cell(action)="data">
					<b-dropdown size="sm" variant="link" right toggle-class="text-decoration-none" :no-caret="true">
						<template #button-content>
							<i class="fe fe-more-horizontal"></i>
						</template>
						<b-dropdown-item class="text-sm font-medium text-[#000005]" @click.prevent="initEditCharge(data.item)">Edit</b-dropdown-item>
						<b-dropdown-item class="text-sm font-medium text-danger" @click.prevent="deleteCharge(data.item.id)">Delete</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>
			<b-pagination v-if="chargeTypes.length" v-model="currentPage" :total-rows="chargeTypeMetadata?.total" :per-page="perPage"></b-pagination>
		</div>
	</div>
</template>

<script setup>
import {ref, watch} from 'vue'
import moment from 'moment'
import {useDeleteCharge, useCreateChargeType, useFetchChargeTypes} from '../../composables/charges/chargeTypes/index'

const { initEditCharge } = useCreateChargeType()
const { deleteCharge } = useDeleteCharge()
const { loading, fetchAllChargeTypes, chargeTypes, chargeTypeMetadata, currentPage, perPage, search } = useFetchChargeTypes()
const fields = [
	{ key: 'sn', label: 'S/N' },
	{ key: 'type', label: 'Charges type' },
	{ key: 'desc', label: 'Description' },
	{ key: 'updated_by', label: 'Updated by' },
	{ key: 'time', label: 'Time/date' },
	{ key: 'action', label: 'Action' }
]

watch([currentPage, search], () => {
	fetchAllChargeTypes()
})

fetchAllChargeTypes()
currentPage.value = 1
</script>

<style scoped>
h1,h2,h3,h4,h5,h6,span, label, p{
	margin: 0 !important;
}
</style>